import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import './App.scss';
import Fade from 'react-reveal/Fade';

class App extends Component {
  render() {
    return (
      <div className="App">
        
        <Fade>
        <header className="App-header">
            <h2>Shawn Snyder</h2>
            <h3>Full Stack Dev / IOS Dev</h3>
        </header>
           
        <Grid container spacing={0} className=''>
            <Grid className='sectionTitle' item xs={12} >
                <h3>Recent Projects</h3>
            </Grid>
        </Grid>
        </Fade>

        <Grid container spacing={3} className='projectParent'>
            <Grid className='projectTitle' item xs={12} sm={3}>
                <h3>Fitplan - 24 Hour Fitness</h3> 
                <p> I recently created a web/kiosk tool for class and workout scheduling.  Workout plans and schedules worked in conjunction with the 24GO app.</p>
                <a target="_blank" href={`https://24go.co/fitplan`}>https://24go.co/fitplan</a>
            </Grid>
            <Grid className='projectItems' item xs={12} sm={9}>
                <div className='projectCarousel'>
                    <Fade>
                    <div className='projectCard'>
                        <img class="card--content" src="/img/fp/1.png"/>
                    </div>
                    </Fade>

                    <Fade>
                    <div className='projectCard'>
                        <img class="card--content" src="/img/fp/2.png"/>
                    </div>
                    </Fade>

                    <Fade>
                    <div className='projectCard'>
                        <img class="card--content" src="/img/fp/3.png"/>
                    </div>
                    </Fade>

                    <Fade>
                    <div className='projectCard'>
                        <img class="card--content" src="/img/fp/4.png"/>
                    </div>
                    </Fade>

                    <Fade>
                    <div className='projectCard'>
                        <img class="card--content" src="/img/fp/5.png"/>
                    </div>
                    </Fade>

                    <Fade>
                    <div className='projectCard'>
                        <img class="card--content" src="/img/fp/6.png"/>
                    </div>
                    </Fade>

                    <Fade>
                    <div className='projectCard'>
                        <img class="card--content" src="/img/fp/7.png"/>
                    </div>
                    </Fade>

                    <Fade>
                    <div className='projectCard'>
                        <img class="card--content" src="/img/fp/8.png"/>
                    </div>
                    </Fade>

                    <Fade>
                    <div className='projectCard'>
                        <img class="card--content" src="/img/fp/9.png"/>
                    </div>
                    </Fade>
                </div>
            </Grid>
        </Grid>

        <Grid container spacing={3} className='projectParent'>
            <Grid className='projectTitle' item xs={12} sm={3}>
                <h3>24GO - 24 Hour Fitness</h3> 
                <p>My second app in the app store is a fitness app for 24 Hour Fitness built in Swift.  The app is used for personalized workouts, scheduling, and fitness advice.  The app has over 1 million registered users and 4 1/2 stars. </p>
                <a target="_blank" href={`https://apps.apple.com/us/app/24go-by-24-hour-fitness/id1291447822`}>App Store</a>
            </Grid>
            <Grid className='projectItems' item xs={12} sm={9}>
                <div className='projectCarousel'>
                    <Fade>
                    <div className='projectCard'>
                        <img class="card--content" src="/img/go/2.jpg"/>
                    </div>
                    </Fade>
                    <Fade>
                    <div className='projectCard'>
                        <img class="card--content" src="/img/go/6.jpg"/>
                    </div>
                    </Fade>

                    <Fade>
                    <div className='projectCard'>
                        <img class="card--content" src="/img/go/0.gif"/>
                    </div>
                    </Fade>

                    <Fade>
                    <div className='projectCard'>
                        <img class="card--content" src="/img/go/3.jpg"/>
                    </div>
                    </Fade>

                    <Fade>
                    <div className='projectCard'>
                        <img class="card--content" src="/img/go/4.jpg"/>
                    </div>
                    </Fade>

                    <Fade>
                    <div className='projectCard'>
                        <img class="card--content" src="/img/go/5.jpg"/>
                    </div>
                    </Fade>
                </div>
            </Grid>
        </Grid>

        <Grid container spacing={3} className='projectParent'>
            <Grid className='projectTitle' item xs={12} sm={3}>
                <h3>Dumbo Reflector - Dumbo NY</h3> 
                <p>Developed a management tool for an art installation in Dumbo.  Users could customize video content in the tool and the sign could be configured to trigger animations based on real time tweet hashtags.</p>
                <a target="_blank" href={`https://technical.ly/brooklyn/2016/10/14/dumbo-reflector-social-media-responsive/
`}>This new DUMBO sign can read the internet</a>
            </Grid>



            <Grid className='projectItems' item xs={12} sm={9}>
                <div className='projectCarousel'>
                    <Fade>
                    <div className='projectCard'>
                        <img class="card--content" src="/img/db/1.jpg"/>
                    </div>
                    </Fade>

                    <Fade>
                    <div className='projectCard'>
                        <img class="card--content" src="/img/db/2.jpg"/>
                    </div>
                    </Fade>

                    <Fade>
                    <div className='projectCard'>
                        <img class="card--content" src="/img/db/3.png"/>
                    </div>
                    </Fade>

                </div>
            </Grid>
        </Grid>



        <Grid container spacing={3} className='projectParent'>
            <Grid className='projectTitle' item xs={12} sm={3}>
                <h3>DGR - 24 Hour Fitness</h3> 
                <p>Created a kiosk app to manage guest and member registrations at 24 Hour Fitness</p>
            </Grid>
            <Grid className='projectItems' item xs={12} sm={9}>
                <div className='projectCarousel'>
                    <Fade>
                    <div className='projectCard'>
                        <img class="card--content" src="/img/dgr/1.png"/>
                    </div>
                    </Fade>

                    <Fade>
                    <div className='projectCard'>
                        <img class="card--content" src="/img/dgr/2.png"/>
                    </div>
                    </Fade>

                    <Fade>
                    <div className='projectCard'>
                        <img class="card--content" src="/img/dgr/3.png"/>
                    </div>
                    </Fade>
                </div>
            </Grid>
        </Grid>


        <Grid container spacing={3} className='projectParent'>
            <Grid className='projectTitle' item xs={12} sm={3}>
                <h3>SFGH - Interactive Donor Wall</h3> 
                <p>Created an interactive donor tribute wall with one additional developer. Created a multi user TUIO touch layer 
with multi finger to single touch dampening.</p>
                <a target="_blank" href={`https://vimeo.com/185052516`}>Vimeo: SFGH Donor Wall</a>
            </Grid>
            <Grid className='projectItems' item xs={12} sm={9}>
                <div className='projectCarousel'>
                    <Fade>
                    <div className='projectCard'>
                        <img class="card--content" src="/img/SFGH/1.jpg"/>
                    </div>
                    </Fade>

                    <Fade>
                    <div className='projectCard'>
                        <img class="card--content" src="/img/SFGH/2.jpg"/>
                    </div>
                    </Fade>

                    <Fade>
                    <div className='projectCard'>
                        <img class="card--content" src="/img/SFGH/3.jpg"/>
                    </div>
                    </Fade>
                </div>
            </Grid>
        </Grid>

        <Grid container spacing={3} className='projectParent'>
            <Grid className='projectTitle' item xs={12} sm={3}>
                <h3>Wavewall - SXSW</h3> 
                <p>Created interactive software to detect motion and trigger animations in front of a wall of LED lights using a 
	playstation Eye.</p>
                <a target="_blank" href={`https://vimeo.com/152765093`}>https://vimeo.com/152765093</a>
            </Grid>
            <Grid className='projectItems' item xs={12} sm={9}>
                <div className='projectCarousel'>
                    <Fade>
                    <div className='projectCard'>
                        <img class="card--content" src="/img/ww/1.jpg"/>
                    </div>
                    </Fade>

                    <Fade>
                    <div className='projectCard'>
                        <img class="card--content" src="/img/ww/3.gif"/>
                    </div>
                    </Fade>

                    <Fade>
                    <div className='projectCard'>
                        <img class="card--content" src="/img/ww/2.png"/>
                    </div>
                    </Fade>

                </div>
            </Grid>
        </Grid>

        <Grid container spacing={3} className='projectParent'>
            <Grid className='projectTitle' item xs={12} sm={3}>
                <h3>Homeaway - Helpcenter</h3> 
                <p>Recreated and improved HomeAway's help center with two other developers.</p>
                <a target="_blank" href={`https://help.homeaway.com`}>https://help.homeaway.com</a>
            </Grid>
            <Grid className='projectItems' item xs={12} sm={9}>
                <div className='projectCarousel'>
                    <Fade>
                    <div className='projectCard'>
                        <img class="card--content" src="/img/ha/1.png"/>
                    </div>
                    </Fade>

                    <Fade>
                    <div className='projectCard'>
                        <img class="card--content" src="/img/ha/2.png"/>
                    </div>
                    </Fade>

                    <Fade>
                    <div className='projectCard'>
                        <img class="card--content" src="/img/ha/3.png"/>
                    </div>
                    </Fade>
                </div>
            </Grid>
        </Grid>

        <Grid container spacing={3} className='projectParent'>
            <Grid className='projectTitle' item xs={12} sm={3}>
                <h3>Intersection - SXSW</h3> 
                <p>Developed an interactive art installation for SXSW using a Leap controller to control an LED light installation. </p>
                <a target="_blank" href={`https://vimeo.com/100832688`}>Vimeo: Intersection</a>
            </Grid>
            <Grid className='projectItems' item xs={12} sm={9}>
                <div className='projectCarousel'>
                    <Fade>
                    <div className='projectCard'>
                        <img class="card--content" src="/img/is/1.jpg"/>
                    </div>
                    </Fade>

                    <Fade>
                    <div className='projectCard'>
                        <img class="card--content" src="/img/is/2.jpg"/>
                    </div>
                    </Fade>

                    <Fade>
                    <div className='projectCard'>
                        <img class="card--content" src="/img/is/3.jpg"/>
                    </div>
                    <div className='projectCard'>
                        <img class="card--content" src="/img/is/4.jpg"/>
                    </div>
                    </Fade>
                </div>
            </Grid>
        </Grid>

        <Grid container spacing={3} className='projectParent'>
            <Grid className='projectTitle' item xs={12} sm={3}>
                <h3>UCSF - Interactive Donor Wall</h3> 
                <p>Created an interactive donor tribute wall with one additional developer. Developed multiple games and an audio recording multi 
	track game with JS and PIXIJS. User movement tracking was created in OpenCV. </p>
                <a target="_blank" href={`https://vimeo.com/155147512`}>Vimeo: UCSF UI</a>
            </Grid>
            <Grid className='projectItems' item xs={12} sm={9}>
                <div className='projectCarousel'>
                    <Fade>
                    <div className='projectCard'>
                        <img class="card--content" src="/img/ucsf/2.jpg"/>
                    </div>
                    </Fade>

                    <Fade>
                    <div className='projectCard'>
                        <img class="card--content" src="/img/ucsf/1.jpg"/>
                    </div>
                    </Fade>

                    <Fade>
                    <div className='projectCard'>
                        <img class="card--content" src="/img/ucsf/3.jpg"/>
                    </div>
                    </Fade>

                </div>
            </Grid>
        </Grid>

        <Grid container spacing={3} className='projectParent'>
            <Grid className='projectTitle' item xs={12} sm={3}>
                <h3>St. Luke's Hospital</h3> 
                <p>Created my first interactive wall with one other developer.  The wall showcased this history of St. Lukes Hospital in Kansas City.</p>
                <a target="_blank" href={`https://www.youtube.com/watch?v=G3bvXL1w1HQ`}>Youtube: St. Lukes History Wall</a>
            </Grid>
            <Grid className='projectItems' item xs={12} sm={9}>
                <div className='projectCarousel'>
                    <Fade>
                    <div className='projectCard'>
                        <img class="card--content" src="/img/sl/1.jpg"/>
                    </div>
                    </Fade>

                    <Fade>
                    <div className='projectCard'>
                        <img class="card--content" src="/img/sl/2.jpg"/>
                    </div>
                    </Fade>
                </div>
            </Grid>
        </Grid>
      </div>
    );
  }
}

export default App;

                        //<img class="card--content" src="/img/fp/1.png"/>
